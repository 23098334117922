<template>
  <div class="search">
    <header>
      <form action="/">
        <van-search
          v-model="searchKey"
          show-action
          placeholder="请输入搜索关键词"
          @search="onSearch"
          @cancel="onCancel"
        />
      </form>
      <van-dropdown-menu v-show="searched" active-color="#00a0ff">
        <van-dropdown-item v-model="sortType" :options="option1" @change="onSortTypeChange" />
        <van-dropdown-item v-model="productType" :options="option2" @change="onSortTypeChange" />
        <van-dropdown-item title="筛选" ref="item">
          <div class="sort-bar-button-box">
            <van-button size="small" :type="isStock == 1 ? 'info' : 'default'" @click="onClickSort(1)">只看有货</van-button>
            <van-button size="small" :type="isCommission == 1 ? 'info' : 'default'" @click="onClickSort(2)">只看激励宝商品</van-button>
            <van-button size="small" :type="isFreight == 1 ? 'info' : 'default'" @click="onClickSort(3)">包运费</van-button>
            <van-button size="small" :type="isInstall == 1 ? 'info' : 'default'" @click="onClickSort(4)">包安装</van-button>
          </div>
          <div>
            <van-button type="default" style="width: 50%;" @click="onReset">重置</van-button>
            <van-button type="primary" color="#00a0ff" style="width: 50%;" @click="onConfirm">确认</van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </header>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '没有更多了' : ''"
        @load="fetchSearch"
      >
        <div class="prod-list">
          <div class="prod-item" v-for="item in list" :key="item.productId" @click="onClickProd(item.productId)">
            <van-image
              lazy-load
              :src="item.titleImg"
            />
            <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
            <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
              <span>￥{{ item.price }}</span>
              <span>选购</span>
            </div>
            <div v-else class="price_pannel">
              <span>买方报价</span>
              <span>报价</span>
            </div>
          </div> 
        </div>
      </van-list>
      <van-empty v-show="searched && list.length == 0" description="暂无数据" />
    </div>
    <GoTop />
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Lazyload } from 'vant';
import Fetch from '@/common/fetch';
import { SearchSell } from '@/common/interface';
import GoTop from '../components/GoTop.vue';

Vue.use(Toast);
Vue.use(Lazyload);

export default {
  name: "Search",
  components: { GoTop },
  data() {
    return {
      curPage: 1,
      searchKey: '',
      searched: false,  //索索过，显示排序面板
      sortType: 0,  // 0综合排序 1上新时间 2价格升序 3价格降序
      productType: 1,
      option1: [
        { text: '综合排序', value: 0 },
        { text: '上新时间', value: 1 },
        { text: '价格从低到高', value: 2 },
        { text: '价格从高到低', value: 3 },
      ],
      option2: [
        { text: '锅炉', value: 1 },
        { text: '燃烧机', value: 2 },
        { text: '辅机', value: 3 },
      ],
      isStock: 0,  //只看有货
      isCommission: 0,  //只看激励宝商品
      isFreight: 0, //包含运费
      isInstall: 0, //包含安装
      loading: false,
      finished: false,
      list: [],
    };
  },
  methods: {
    onSearch() {
      this.curPage = 1;
      this.fetchSearch();
    },

    onCancel() {
      this.$router.go(-1);
    },

    onSortTypeChange() {
      this.curPage = 1;
      this.fetchSearch();
    },

    onClickSort(type) {
      switch (type) {
        case 1:
          this.isStock = 1^this.isStock;
          break;
        case 2:
          this.isCommission = 1^this.isCommission;
          break;
        case 3:
          this.isFreight = 1^this.isFreight;
          break;
        case 4:
          this.isInstall = 1^this.isInstall;
          break;
        default:
          break;
      }
    },

    onReset() {
      this.isStock = this.isCommission = this.isFreight = this.isInstall = 0;
    },

    onConfirm() {
      this.$refs.item.toggle();
      this.curPage = 1;
      this.fetchSearch();
    },

    fetchSearch() {
      this.searched = true;
      const page = this.curPage;
      const params = {
        productName: this.searchKey,
        productType: this.productType,
        sortType: this.sortType,
        isCommission: this.isCommission,
        isStock: this.isStock,
        isFreight: this.isFreight,
        isInstall: this.isInstall,
        pageNum: page,
        pageSize: 20,
      };
      Fetch.post(SearchSell, params).then(res => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = page == 1 ? resList : this.list.concat(resList);
        this.curPage = page + 1;
        this.list = newList;
        this.loading = false;
        this.finished = finished;
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

    onClickProd(productId) {
      this.$router.push('/product?id=' + productId);
    },
  },
}
</script>

<style lang="scss" scoped>
.sort-bar-button-box {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  button {
    width: 35%;
    margin-bottom: 20px;
  }
}
</style>