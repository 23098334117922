
// 本地
// const HOST = 'http://192.168.1.88:8080';

// 测试
// const HOST = 'http://howar.imwork.net:23978';

// 线上
const HOST = 'https://www.etb618.com:8080';

const INFO_OPTIONS = {
  // 属性
  attribute: {
    1: '蒸汽',
    2: '热水',
    3: '导热油',
    4: '余热',
    5: '热风',
    6: '垃圾焚烧',
    99: '其他',
  },
  // 成色
  condition: {
    1: '全新',
    2: '准新',
    99: '二手',
  },
  // 燃烧机结构
  burner_structure: {
    1: '分体机',
    2: '一体机',
  },
  // 锅炉结构
  boiler_structure: {
    1: '标准三回程',
    2: '链条/往复炉排',
    3: '中心回燃',
    4: '流化床',
    99: '其他',
  },
  // 吨位
  tonnage: {
    1: '0.5t/h',
    2: '1t/h',
    3: '2t/h',
    4: '4t/h',
    5: '5t/h',
    6: '6t/h',
    7: '8t/h',
    8: '10t/h',
    9: '15t/h',
    10: '20t/h',
    99: '其他',
  },
  // 功率
  power: {
    1: '0.35MW',
    2: '0.7MW',
    3: '1.4MW',
    4: '2.8MW',
    5: '3.5MW',
    6: '4.2MW',
    7: '5.6MW',
    8: '7MW',
    9: '10.5MW',
    10: '14MW',
    99: '其他',
  },
  // 压力
  pressure: {
    1: '常压',
    2: '真空',
    3: '0.5兆帕',
    4: '0.75兆帕',
    5: '1.0兆帕',
    6: '1.25兆帕',
    7: '1.6兆帕',
    8: '2.5兆帕',
    9: '3.82兆帕',
    99: '其他',
  },
  // 技术
  technology: {
    1: '全预混表面燃烧',
    2: '烟气循环（FGR/FIR）',
    99: '其他',
  },
  // 排放
  emission: {
    1: '超低氮Nox ≤30mg',
    2: '低氮Nox ≤80mg',
    3: '普通非低氮',
  },
  // 形式选项
  modality: {
    1: '卧式',
    2: '立式',
    99: '其他',
  },
  // 生产情况
  produceState: {
    1: '现货',
    2: '未生产',
  },
  // 生产资质等级
  firmQualificate: {
    1: 'A级',
    2: 'B级',
    3: 'C级',
  },
  // 运行情况
  runStatus: {
    1: '运行正常',
    2: '需要修理',
  },
  // 燃料
  fuel: [
    {
      title: '燃气',
      value: 100,
      selectable: false,
      children: [
        {
          title: '天然气',
          value: 101,
        },
        {
          title: '液化石油气',
          value: 102,
        },
        {
          title: '沼气',
          value: 103,
        },
        {
          title: '甲烷',
          value: 104,
        },
        {
          title: '高炉尾气',
          value: 105,
        },
      ],
    },
    {
      title: '燃油',
      value: 200,
      selectable: false,
      children: [
        {
          title: '柴油',
          value: 201,
        },
        {
          title: '机油',
          value: 202,
        },
        {
          title: '煤油',
          value: 203,
        },
      ],
    },
    {
      title: '燃煤',
      value: 300,
      selectable: false,
      children: [
        {
          title: '型煤',
          value: 301,
        },
        {
          title: '煤粉',
          value: 302,
        },
      ],
    },
    {
      title: '生物质',
      value: 400,
      selectable: false,
      children: [
        {
          title: '秸秆',
          value: 401,
        },
        {
          title: '锯末',
          value: 402,
        },
        {
          title: '甘蔗渣',
          value: 403,
        },
        {
          title: '稻糠',
          value: 404,
        },
        {
          title: '鸡粪',
          value: 405,
        },
      ],
    },
    {
      title: '醇基',
      value: 500,
      selectable: false,
      children: [
        {
          title: '甲醇',
          value: 501,
        },
        {
          title: '乙醇',
          value: 502,
        },
      ],
    },
    {
      title: '电',
      value: 600,
    },
  ],

  // 锅炉相关资料
  boiler_report_list: [
    {
      name: 'totalImg',
      label: '总图',
    },
    {
      name: 'bodyImg',
      label: '本体图',
    },
    {
      name: 'groundImg',
      label: '地基图',
    },
    {
      name: 'pipelineImg',
      label: '管道图',
    },
    {
      name: 'circuitImg',
      label: '电路图',
    },
    {
      name: 'thermalBookImg',
      label: '热力计算书',
    },
    {
      name: 'resistanceBookImg',
      label: '烟风阻力计算书',
    },
    {
      name: 'intensityBookImg',
      label: '强度计算书',
    },
    {
      name: 'manualImg',
      label: '说明书',
    },
    {
      name: 'energyReportImg',
      label: '能效测试报告',
    },
    {
      name: 'epReportImg',
      label: '环保检测报告',
    },
    {
      name: 'qualifiedCertifiedImg',
      label: '产品合格证',
    },
    {
      name: 'importDeclarationImg',
      label: '进口报关单',
    },
  ],

  // 燃烧机相关资料
  burner_report_list: [
    {
      name: 'experimentBookImg',
      label: '形式试验证书',
    },
    {
      name: 'experimentReportImg',
      label: '形式试验报告',
    },
    {
      name: 'epReportImg',
      label: '环保检测报告',
    },
    {
      name: 'manualImg',
      label: '说明书',
    },
    {
      name: 'qualifiedCertifiedImg',
      label: '产品合格证',
    },
    {
      name: 'importDeclarationImg',
      label: '进口报关单',
    },
  ],

  // 辅机相关资料
  auxiliary_report_list: [
    {
      name: 'experimentBookImg',
      label: '形式试验证书',
    },
    {
      name: 'experimentReportImg',
      label: '形式试验报告',
    },
    {
      name: 'epReportImg',
      label: '环保检测报告',
    },
    {
      name: 'manualImg',
      label: '说明书',
    },
    {
      name: 'qualifiedCertifiedImg',
      label: '产品合格证',
    },
    {
      name: 'importDeclarationImg',
      label: '进口报关单',
    },
  ],

  // 用途
  purpose: {
    1: '供热',
    2: '生产',
    99: '其他',
  },
  // 发票要求
  requestInvoice: {
    1: '增值税专票（一般纳税人开具）',
    2: '增值税专票（不限开具方）',
    3: '增值税普通发票',
    4: '不用发票',
  },
};

const SERVICE_TYPE = {
  1: '拆除/安装/验收',
  2: '资料',
  3: '能效测试',
  4: '环保检测',
  5: '运输',
  6: '维保',
  7: '运行',
  8: '维修/翻新',
};

export {
  HOST,
  INFO_OPTIONS,
  SERVICE_TYPE,
};
