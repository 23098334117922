<template>
  <van-tabbar v-model="active" placeholder :before-change="beforeChange">
    <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
    <van-tabbar-item icon="search" to="/search">产品</van-tabbar-item>
    <van-tabbar-item icon="cart-o" to="/cart">购物车</van-tabbar-item>
    <van-tabbar-item icon="user-o" to="/user">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    value: Number
  },
  data() {
    return {
      active: this.value
    }
  },
  methods: {
    beforeChange(name) {
      if (name == 0) {
        this.$router.push('/');
        return false;
      }
      if (name == 1) {
        this.$router.push('/search');
        return false;
      }
      if (name == 2) {
        this.$router.push('/cart');
        return false;
      }
      if (name == 3) {
        this.$router.push('/user');
        return false;
      }
    }
  }
}
</script>