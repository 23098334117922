import md5 from 'js-md5';
import Fetch from '../fetch';
import { GetCity } from '../interface';
import { INFO_OPTIONS } from '../const';

export default {

  // 获取options的name
  mapValueToName(options, value) {
    let result;
    options.map((option) => {
      if (String(option.value) === String(value)) {
        result = String(option.name);
      }
      return option;
    });
    return result;
  },
  // 根据字段名从链接中获取值
  getQueryString(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  getResizeImg(url) {
    // if (url && size) {
    //   return `${url}?x-oss-process=image/resize,w_${size},h_${size},limit_0`;
    // }
    return url;
  },
  getOriginalUrl(url) {
    if (url && url.includes('?')) {
      return url.split('?')[0];
    }
    return url;
  },
  queryParams(data) {
    const _result = [];
    for (const key in data) {
      const value = data[key];
      if (!['', undefined, null].includes(value)) {
        _result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return _result.length ? _result.join('&') : '';
  },
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  },
  md5(str) {
    return md5(str);
  },

  getRemainingTimeDescrip(result) {
    if (result > 0) {
      const second = Math.floor(result % 60);
      const minute = Math.floor((result / 60) % 60);
      return `${minute}分${second}秒`;
    } else {
      return '0分0秒';
    }
  },

  formatTime(timespan) {
    const dateTime = new Date(parseInt(timespan, 10));
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();
    const now = new Date();
    const nowNew = now.getTime();
    let milliseconds = 0;
    let timeSpanStr;

    milliseconds = nowNew - timespan;

    if (milliseconds <= 1000 * 60 * 1) {
      timeSpanStr = '刚刚';
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
      timeSpanStr = `${Math.round((milliseconds / (1000 * 60)))}分钟前`;
    } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
      timeSpanStr = `${Math.round(milliseconds / (1000 * 60 * 60))}小时前`;
    } else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) {
      timeSpanStr = `${Math.round(milliseconds / (1000 * 60 * 60 * 24))}天前`;
    } else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
      timeSpanStr = `${month}-${day} ${hour}:${minute}`;
    } else {
      timeSpanStr = `${year}-${month}-${day} ${hour}:${minute}`;
    }
    return timeSpanStr;
  },

  // 读取cookies
  getCookie(name) {
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    const arr = document.cookie.match(reg);
    if (arr) {
      return unescape(arr[2]);
    }
    return '';
  },

  getFormItemOptions(origin_object) {
    const keys = Object.keys(origin_object);
    const options = keys.map(key => ({
      value: parseInt(key, 10),
      label: origin_object[key],
    }));
    return options;
  },

  getFormItemValue(option, data, defaultKey) {
    if (!data) {
      return '-';
    }
    if (option === 'fuel') {
      return this.getFuelValue(data);
    }
    const key = defaultKey || data[option];
    const options = INFO_OPTIONS[option];
    if (options) {
      if (parseInt(key, 10) === 99) {
        // 其他
        if (option === 'condition') { // 成色
          if (data[`${option}Other`]) {
            return `二手/${data[`${option}Other`]}`;
          }
          return '二手';
        }
        if (option.includes('structure')) { // 结构
          if (data.structureOther) {
            return `其他/${data.structureOther}`;
          }
          return '其他';
        }
        if (data[`${option}Other`]) {
          return `其他/${data[`${option}Other`]}`;
        }
        return '其他';
      }
      return options[key] || '-';
    }
    return '-';
  },

  getFuelValue(data) {
    if (!data) {
      return '-';
    }
    const key = parseInt(data.fuel, 10);
    const fuel_options = INFO_OPTIONS.fuel;
    let fuelType = '';
    fuel_options.forEach((e) => {
      if (key - e.value < 100 && key - e.value >= 0) {
        fuelType = e.title;
        if (Array.isArray(e.children)) {
          e.children.forEach((subE) => {
            if (subE.value === key) {
              fuelType = fuelType.concat('/', subE.title);
            }
          });
        }
      }
    });
    return fuelType;
  },

  updateCityCode() {
    Fetch.post(GetCity, {}).then((res) => {
      const city_list = res;
      const length = city_list.length;
      for (let index = 0; index < length; index++) {
        const province = city_list[index];
        province.isLeaf = false;
        const cityCount = province.children.length;
        if (cityCount > 0) {
          for (let j = 0; j < cityCount; j++) {
            const city = province.children[j];
            city.isLeaf = false;
          }
        }
      }
      window.localStorage.setItem('cityList', JSON.stringify(city_list));
    }).catch(() => {
    });
  },

  // 获取省市信息
  getCityList() {
    return new Promise((resolve) => {
      const cityList = JSON.parse(window.localStorage.getItem('cityList'));
      if (cityList) {
        return resolve(cityList);
      }
      Fetch.post(GetCity, {}).then((res) => {
        const city_list = res;
        const length = city_list.length;
        for (let index = 0; index < length; index++) {
          const province = city_list[index];
          province.isLeaf = false;
          const cityCount = province.children.length;
          if (cityCount > 0) {
            for (let j = 0; j < cityCount; j++) {
              const city = province.children[j];
              city.isLeaf = false;
            }
          }
        }
        window.localStorage.setItem('cityList', JSON.stringify(city_list));
        return resolve(city_list);
      }).catch(() => {
        return resolve([]);
      });
    });
  },

  getCityName(cityCode) {
    if (cityCode == 990000) {
      return '进口';
    }
    if (Array.isArray(cityCode)) {
      const provinceCode = cityCode[0];
      let cityName = '';
      const cityList = JSON.parse(window.localStorage.getItem('cityList'));
      if (Array.isArray(cityList)) {
        cityList.forEach((province) => {
          if (province.value === provinceCode) {
            cityName = province.label;
            if (cityCode.length > 1) {
              const city_code = cityCode[1];
              province.children.forEach((city) => {
                if (city.value === city_code) {
                  cityName = cityName.concat('', city.label);
                }
              });
            }
          }
        });
        return cityName;
      }
    } else if (cityCode && cityCode.toString().length === 6) {
      const cityList = JSON.parse(window.localStorage.getItem('cityList'));
      if (Array.isArray(cityList)) {
        let cityName = '';
        const provinceCode = Math.floor(cityCode / 10000) * 10000;
        cityList.forEach((province) => {
          if (parseInt(province.value, 10) === provinceCode) {
            cityName = province.label;
            if (Array.isArray(province.children)) {
              province.children.forEach((city) => {
                if (parseInt(city.value, 10) === parseInt(cityCode, 10)) {
                  cityName = cityName.concat('', city.label);
                }
              });
            }
          }
        });
        return cityName;
      }
    }
    return '-';
  },

  getVideoStills(videoUrl) {
    return videoUrl?.concat('?x-oss-process=video/snapshot,t_1000,f_jpg,w_800,h_600,m_fast') || '';
  },

  // 将数组拆分成length为num的二维数组
  spArr(arr, num) {
    let newArr = [];
    for (let i = 0; i < arr.length;) {
      newArr.push(arr.slice(i, i += num));
    }
    return newArr;
  },

  // 返回保留两位小数的价格字符串
  returnFloat(price) {
    let price_str = Math.round(parseFloat(price) * 100) / 100;
    if (isNaN(price_str)) {
      price_str = 0;
    }
    var s = price_str.toString().split(".");
    if (s.length == 1) {
      price_str = price_str.toString() + ".00";
      return price_str;
    }
    if (s.length > 1) {
      if (s[1].length < 2) {
        price_str = price_str.toString() + "0";
      }
      return price_str;
    }
  }
};
