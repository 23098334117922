import axios from 'axios';
import qs from 'qs';
import { HOST } from '../const';
import User from '../user';
import * as addon from './addon';

const Interceptor = {
  request: [function (request) {
    const reqParams = {};

    const { method, data } = request;
    let { params } = request;
    if (method === 'get') {
      params = params || {};
    } else if (method === 'post') {
      params = data || {};
    }

    Object.keys(params).forEach((key) => {
      if (key === 'undefined' || params[key] === undefined) return;
      reqParams[key] = params[key];
    });
    /**
     * 参数加密：参数内容msg,sign;
     * msg是json格式的参数base64之后的内容；
     * sign是mds(msg+publickey)
     * publickKey暂时写成：abcdefg123456
     */
    // const msg = Base64.encode(JSON.stringify(reqParams));
    // const sign = utils.md5(msg + getCurrentKey());
    // const newParams = { msg, sign };
    if (method === 'get') {
      request.params = reqParams;
      delete request.data;
    } else if (method === 'post') {
      request.data = reqParams;
      delete request.params;
    }

    // 添加headers公共参数
    request.headers.sourceFlag = 'web';
    request.headers.channelCode = '10001';
    request.headers.appOwnership = 'EBei';
    const user = User.getInfo() || {};
    const { token, uuid } = user;
    if (token) {
      request.headers['token'] = token;
    }
    if (uuid) {
      request.headers['uuid'] = uuid;
    }

    return request;
  }],
  response: [function (response) {
    if (/json/.test(response.headers['content-type'])) {
      const { code, message, data } = response.data;
      // 兼容处理
      const msg = message || '请求失败';
      if (Number(code) === 1000) {
        return data; // 业务数据
      } else if (Number(code) === 2001) { // 未登录
        User.showLogin();
      }
      return Promise.reject({ code, msg, data });
    }
    return response; // response对象
  }],
};

class Fetch {
  constructor(config) {
    const fetch = this.create(config); // 系统默认实例
    fetch.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    Object.assign(fetch, addon);
    return fetch;
  }

  create(config) {
    const instance = axios.create(Object.assign({
      baseURL: HOST,
      timeout: 10 * 1000,
      transformRequest: [function (data) {
        return qs.stringify(data);
      }],
    }, config));
    instance.interceptors.request.use(...Interceptor.request);
    instance.interceptors.response.use(...Interceptor.response);

    return instance;
  }
}

export default new Fetch();
