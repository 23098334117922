const EXPORT_TIMEOUT = 10000;

export function file(url, params = {}, filename) {
  return this.get(url, params, {
    timeout: EXPORT_TIMEOUT,
    responseType: 'blob',
  }).then((res) => {
    if (!filename) {
      const content = res.headers['content-disposition'];
      const exportName = res.headers['export-filename'];
      filename = content ? content.match(/filename=(\S+\.[a-zA-Z0-9]+$)/)[1] : exportName;
    }
    filename = decodeURIComponent(filename);
    // 创建a标签, 输出文件
    if ('msSaveOrOpenBlob' in navigator) {
      // Microsoft Edge and Microsoft Internet Explorer 10-11
      window.navigator.msSaveOrOpenBlob(res.data, filename);
    } else {
      const link = document.createElement('a');
      const fileurl = window.URL.createObjectURL(res.data);
      link.href = fileurl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileurl);
    }
    return {
      type: 'export',
      retcode: 200,
      retdesc: '文件导出成功',
    };
  }).catch(err => Promise.reject({
    retcode: 210,
    retdesc: err,
  }));
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise}
 */
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
}

/**
* 保存
* @param  {Blob} blob
* @param  {String} filename 想要保存的文件名称
*/
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
* 下载
* @param  {String} url 目标文件地址
* @param  {String} filename 想要保存的文件名称
*/
export function downloadFile(url, filename) {
  getBlob(url).then((blob) => {
    saveAs(blob, filename);
  });
}
