
const User = {
  setInfo(user) {
    window.localStorage.setItem('user', JSON.stringify(user));
  },
  getInfo() {
    try {
      const user = JSON.parse(window.localStorage.getItem('user') || '');
      return user;
    } catch (error) {
      return undefined;
    }
  },
  modifyInfo(data) {
    const old = this.getInfo() || {};
    const tmp = {
      ...old,
      ...data,
    };
    window.localStorage.setItem('user', JSON.stringify(tmp));
  },
  removeInfo() {
    window.localStorage.removeItem('user');
  },
  // 通过邀请链接进入，弹出注册面板
  showRegister(invite_code) {
    if (document.getElementById('login')) {
      return;
    }
    console.log(invite_code)
    //...TODO

  },
  showLogin() {
    if (document.getElementById('login')) {
      return;
    }
    this.removeInfo();

    //...TODO
  },
  setCity(city) {
    window.localStorage.setItem('city', JSON.stringify(city));
  },
  getCity() {
    try {
      const city = JSON.parse(window.localStorage.getItem('city'));
      return city;
    } catch (error) {
      return null;
    }
  },
};

export default User;
