import Vue from 'vue'
import { Tabbar, TabbarItem, Swipe, SwipeItem, Button, Grid, GridItem, Icon,
  Tab, Tabs, Image as VanImage, List, Search, Empty, DropdownMenu, DropdownItem } from 'vant';

import App from './App.vue'
import User from './common/user';
import router from './router'

import utils from './common/utils';

Vue.config.productionTip = false

Vue.use(Tabbar).use(TabbarItem).use(Swipe).use(SwipeItem).use(Button).use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Search);
Vue.use(Empty);
Vue.use(DropdownMenu).use(DropdownItem);

router.beforeEach((to, from, next) => {
  let isLogin = User.getInfo();   //登录时存的数据
  if (to.matched.some(record => record.meta.requireLogin)) {  //路由页面是否有meta值
    if ( to.name !== 'Login' && !isLogin ) {    
      next({ path: '/login', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else {
    next() 
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

utils.updateCityCode();


