<template>
  <div class="home">
    <header class="home-header">
      <img src="../assets/headerLogo.png" />
      <van-button icon="search" type="primary" round block size="small" color="#00a0ff" @click="routeToSearch">寻找锅炉设备</van-button> 
    </header>
    <div class="home-swiper">
      <van-swipe :autoplay="3000" width="100%">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="onClickBanner(item)">
          <img v-lazy="item.imgAddr" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="home-cate">
      <van-grid :column-num="3">
        <van-grid-item icon="gem-o" text="产品" @click="routeToSearch" />
        <van-grid-item icon="shop-o" text="店铺" to="/storemall" />
        <van-grid-item icon="flag-o" text="商家入驻" to="/settlein" />
        <van-grid-item icon="bar-chart-o" text="碳达峰碳中和" to="/carbon" />
        <van-grid-item icon="friends-o" text="产业联盟" to="/union" />
        <van-grid-item icon="refund-o" text="融资/租赁" to="/applyproj" />
      </van-grid>
    </div>
    <div class="home-tabs">
      <van-tabs v-model="activeTab" color="#00a0ff" @click="onClickTab">
        <van-tab name="0" title="推荐">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchProdList"
          >
            <div class="prod-list">
              <div class="prod-item" v-for="item in prodList" :key="item.productId" @click="onClickProd(item.productId)">
                <van-image
                  lazy-load
                  :src="item.titleImg"
                />
                <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                  <span>￥{{ item.price }}</span>
                  <span>选购</span>
                </div>
                <div v-else class="price_pannel">
                  <span>买方报价</span>
                  <span>报价</span>
                </div>
              </div> 
            </div>
          </van-list>
        </van-tab>
        <van-tab name="4" title="最新发布">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchProdList"
          >
            <div class="prod-list">
              <div class="prod-item" v-for="item in prodList" :key="item.productId" @click="onClickProd(item.productId)">
                <van-image
                  lazy-load
                  :src="item.titleImg"
                />
                <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                  <span>￥{{ item.price }}</span>
                  <span>选购</span>
                </div>
                <div v-else class="price_pannel">
                  <span>买方报价</span>
                  <span>报价</span>
                </div>
              </div> 
            </div>
          </van-list>
          <van-empty v-show="prodList.length == 0" description="暂无数据" />
        </van-tab>
        <van-tab name="1" title="锅炉">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchProdList"
          >
            <div class="prod-list">
              <div class="prod-item" v-for="item in prodList" :key="item.productId" @click="onClickProd(item.productId)">
                <van-image
                  lazy-load
                  :src="item.titleImg"
                />
                <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                  <span>￥{{ item.price }}</span>
                  <span>选购</span>
                </div>
                <div v-else class="price_pannel">
                  <span>买方报价</span>
                  <span>报价</span>
                </div>
              </div> 
            </div>
          </van-list>
          <van-empty v-show="prodList.length == 0" description="暂无数据" />
        </van-tab>
        <van-tab name="2" title="燃烧机">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchProdList"
          >
            <div class="prod-list">
              <div class="prod-item" v-for="item in prodList" :key="item.productId" @click="onClickProd(item.productId)">
                <van-image
                  lazy-load
                  :src="item.titleImg"
                />
                <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                  <span>￥{{ item.price }}</span>
                  <span>选购</span>
                </div>
                <div v-else class="price_pannel">
                  <span>买方报价</span>
                  <span>报价</span>
                </div>
              </div> 
            </div>
          </van-list>
          <van-empty v-show="prodList.length == 0" description="暂无数据" />
        </van-tab>
        <van-tab name="3" title="辅机">
           <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="fetchProdList"
          >
            <div class="prod-list">
              <div class="prod-item" v-for="item in prodList" :key="item.productId" @click="onClickProd(item.productId)">
                <van-image
                  lazy-load
                  :src="item.titleImg"
                />
                <div class="prod-title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div v-if="!!item.price && parseInt(item.price) != 0" class="price_pannel">
                  <span>￥{{ item.price }}</span>
                  <span>选购</span>
                </div>
                <div v-else class="price_pannel">
                  <span>买方报价</span>
                  <span>报价</span>
                </div>
              </div> 
            </div>
          </van-list>
          <van-empty v-show="prodList.length == 0" description="暂无数据" />
        </van-tab>
      </van-tabs>
    </div>
    <TabBar :value="0" />
    <GoTop />
  </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';
import Fetch from '@/common/fetch';
import { GetRecommandList, GetLatestProduct, GetAdList } from '@/common/interface';
import TabBar from "@/components/TabBar.vue";
import GoTop from '../components/GoTop.vue';

Vue.use(Lazyload);

export default {
  name: "Home",
  components: { TabBar, GoTop },
  data() {
    return {
      curPage: 1,
      sort: 'casc',
      bannerList: [],
      prodList: [],
      loading: false,
      finished: false,
      activeTab: '0',
    };
  },

  beforeRouteLeave (to, from, next) {
    if (to.name == 'Search') {  //首页进入搜索页时不缓存搜索页
      to.meta.keepAlive = false;
    }
    next();
  },

  methods: {
    onClickBanner(item) {
      if (item && item.linkAddr) {
        if (item.linkAddr == '/applyproj') {
          this.$router.push('/applyproj');
        } else if (item.linkAddr == '/carbon') {
          this.$router.push('/carbon');
        }
      }
    },
    routeToSearch() {
      this.$router.push('/search')
    },

    getAdList: function () {
      Fetch.post(GetAdList).then((res) => {
        this.bannerList = res.bannerList || [];
      }).catch();
    },

    onClickTab(name) {
      this.curPage = 1;
      this.loading = true;
      this.activeTab = name;
      this.fetchProdList(true);
    },

    fetchProdList: function (clear = false) {
      let sort = this.sort;
      let page = this.curPage;
      if (page == 1) {
        const index = Math.floor(Math.random()*13);
        const sorts = ['casc','cdesc','masc','mdesc','brandasc','branddesc','modelasc','modeldesc','pdatedesc','pressureasc','pressuredesc','tonnageasc','tonnagedesc'];
        sort = sorts[index];
      }

      const params = {
        sortType: sort,
        pageNum: page,
        pageSize: 20,
      };

      let api = GetRecommandList;
      if (this.activeTab == '4') { // 最新发布
        api = GetLatestProduct;
      } else {
        params.productType = this.activeTab;
      }
      
      Fetch.post(api, params).then(res => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = clear ? resList : this.prodList.concat(resList);
        this.sort = sort;
        this.curPage = page + 1;
        this.prodList = newList;
        
        this.loading = false;
        this.finished = finished;
      }).catch(err => {
        console.log(err);
      })
    },
    
    onClickProd(productId) {
      this.$router.push('/product?id=' + productId);
    },
  },

  mounted () {
    this.getAdList();
  }
}
</script>

<style lang="scss">
.home-header {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px 20px;
  img {
    width: 90px;
    margin-right: 20px;
  }
}
.home-swiper {
  img {
    height: 200px;
  }
}
.prod-list {
  padding: 10px 7px;
  display: flex;
  flex-wrap: wrap;
  .prod-item {
    width: 170px;
    margin: 13px 5px 0;
    font-size: 14px;
    img {
      width: 170px;
      height: 170px;
    }
    .prod-title {
      height: 40px;
      line-height: 20px;
    }
    .price_pannel {
      margin-top: 6px;
      width: 100%;
      height: 25px;
      line-height: 25px;
      position: relative;
      border: 1px solid #ff1575;
      border-radius: 2px;
      display: flex;
      span {
        text-align: center;
        font-size: 14px;
      }
      span:nth-of-type(1) {
        flex-grow: 1;
        color: #ff1575;
      }
      span:nth-of-type(2) {
        width: 64px;
        color: #fff;
        background: #ff1575;
        cursor: pointer;
      }
      &:hover {
        border-color: #e51269;
        span:nth-of-type(1) {
          color: #e51269;
        }
        span:nth-of-type(2) {
          background: #e51269;
        }
      }
    }
  }
}
</style>