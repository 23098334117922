
// 获取验证码
export const GetCaptchaCode = '/phone-code/get';


/**  发布 编辑保存产品 */
// 发布出售产品
export const SellProductPublish = '/product/submitSellProduct';
// 编辑保存出售产品
export const SellProductEdit = '/user/editSellProduct';


// 获取国家省市信息
export const GetCity = '/system/cityCodeList';
// 根据市id获取区县信息
export const GetAreaList = '/system/areaCodeList';

// 获取最新产品列表
export const GetLatestProduct = '/index/queryIndexProductList';

// 发布采购产品
export const PublishProductBuy = '/product/submitProductBuy';
// 发布采购服务
export const PublishServiceBuy = '/product/submitServiceBuy';

// 出售产品搜索
export const SearchSell = '/product/searchSellList';
// 采购产品搜索
export const SearchBuy = '/product/searchPurchaseList';
// 获取采购服务列表
export const GetServiceBuyList = '/product/queryBuyServiceList';

// 获取出售产品详细信息
export const GetSellProductDetail = '/product/getSellProductDetail';
// 获取采购产品详细信息
export const GetBuyProductDetail = '/product/getBuyProductDetail';
// 获取采购服务详细信息
export const GetBuyServiceDetail = '/product/getBuyServiceDetail';

// 发布投标单
export const PublishProductBid = '/product/submitProductBid';
// 发布服务投标单
export const PublishServiceBid = '/product/submitServiceBid';

// 用户注册
export const UserRegister = '/user/register';
// 用户登录
export const UserLogin = '/user/login';
// 用户登出
export const UserLogout = '/user/loginOut';
// 获取用户信息
export const GetUserInfo = '/user/getInfo';
// 编辑个人信息
export const EditUserInfo = '/user/update';

// 买家-获取我的采购产品列表
export const GetUserBuyProductList = '/user/myBuyProductList';
// 买家-获取我的采购服务列表
export const GetUserBuyServiceList = '/user/myBuyServiceList';
// 买家-删除采购的产品
export const DeleteBuyProduct = '/user/deleteMyBuyProduct';
// 买家-删除采购的服务
export const DeleteBuyService = '/user/deleteMyBuyService';
// 获取产品采购单的投标列表
export const GetBuyProductBidList = '/product/queryBidderList';
// 获取产品投标详情
export const GetBidDetail = '/product/getBidDetail';
// 获取服务采购单的投标列表
export const GetBuyServiceBidList = '/user/myBidServiceUserList';



// 卖家-获取我的出售列表
export const GetUserSellList = '/user/mySellProductList';

// 编辑保存采购产品
export const BuyProductEdit = '/user/editBuyProduct';
// 卖家-上架产品
export const ProductOnShelf = '/user/onMySellProduct';
// 卖家-下架产品
export const ProductOffShelf = '/user/offMySellProduct';
// 卖家-删除出售的产品
export const DeleteSellProduct = '/user/deleteMySellProduct';
// 卖家-删除产品草稿
export const ProductDraftDelete = '/user/deleteDraft4Sell';
// 卖家-获取我的产品投标列表
export const GetProductBidList = '/user/mySellBidList';
// 卖家-获取我的服务投标列表
export const GetServiceBidList = '/user/mySellBidServiceList';
// 个人中心-获取草稿出售列表
export const GetSellDraftList = '/user/mySellDraftList';
// 个人中心-获取草稿采购列表
export const GetBuyDraftList = '/user/myBuyDraftList';
// 个人中心-删除我的产品投标
export const DeleteProductBid = '/user/deleteMyBidProduct';
// 删除我的服务投标
export const DeleteServiceBid = '/user/deleteMyBidService';
// 卖家-获取订单列表
export const SellerGetOrderList = '/order/querySellerOrderList';

// 产品收藏操作
export const CollectProduct = '/user/switchFavorites';
// 获取我的收藏列表
export const GetMyCollectList = '/user/myFavoritesList';

// 获取历史记录
export const GetHistoryList = '/user/myHistoryList';
// 删除历史记录
export const DeleteHistory = '/user/deleteHistoryById';

// 提交问题反馈信息
export const FeedbackSubmit = '/index/submitFeedback';

// 重置密码
export const ResetPassword = '/user/resetPwd';


// oss
// 产品图片oss通用上传
export const UploadProduct = '/uploadOss/product';
// 富文本内的图片上传
export const UploadRichContentImage = '/uploadOss/productContent';
// 头像上传
export const UploadAvatar = '/uploadOss/headImg';
// 问题反馈文件上传
export const UploadFeedback = '/uploadOss/feedback';
// 服务投标文件上传
export const UploadServiceFile = '/uploadOss/service';
// 上传电子签章
export const UploadUserSign = '/uploadOss/officialSeal';
// 上传企业商标
export const UploadCompanyLogo = '/uploadOss/trademarkImg';
// 删除oss中的文件
export const DeleteOssFile = '/uploadOss/delete';


// 获取轮播图广告信息
export const GetAdList = '/index/findBannerList';

// 获取产品和用户相关的状态（收藏，已添加意向等）
export const GetProductStatus = '/user/getProductStatus';


// 获取未读消息数
export const GetUnReadMessageCount = '/index/getRemainNotice';
// 获取消息中心列表
export const GetMessageList = '/user/queryNotices';
// 标记消息为‘已读’
export const MessageMarkRead = '/user/markReadNotice';



// 买家添加购买意向
export const AddBuyIntention = '/user/addUserIntention';

// 买家获取意向清单
export const BuyerGetIntentionList = '/user/mySelfIntentionList';
// 买家取消产品意向
export const BuyerCancelIntention = '/user/buyer/cancelIntention';
// 买家重新对意向进行报价
export const BuyerRequote = '/user/buyer/intentionPrice';

// 买家详情直接下单
export const PlaceOrderByDetail = '/order/createProduct'
// 意向列表下单
export const PlaceOrderByIntention = '/order/createIntention';

// 卖家获取意向清单
export const SellerGetIntentionList = '/user/myUserIntention';
// 卖家同意报价
export const SellerAgreeQuote = '/user/seller/confirmIntention';
// 卖家重新议价
export const SellerRefuseQuote = '/user/seller/intentionPrice';
// 卖家取消产品意向
export const SellerCancelIntention = '/user/seller/cancelIntention';

// 获取产品推荐列表
export const GetRecommandList = '/index/queryHotProductList';

// 获取品牌列表
export const GetBrandList = '/product/queryBrandList';

// 根据品牌id 获取型号列表
export const GetModelList = '/product/queryModelList';

// 根据品牌id 型号名称获取产品模板参数
export const GetProductTemplete = '/product/getModelDetail';

// 付费方案支付
export const PayForSolution = '/order/payBook';

// 购物车创建订单
export const CartPlaceOrder = '/order/placeShopCart';

// 商品添加购物车
export const AddToCart = '/cart/addProduct';

// 获取购物车列表
export const GetCartList = '/cart/queryList';

// 删除购物车某项
export const DeleteCart = '/cart/delete';


// 店铺logo上传
export const UploadStoreLogo = '/uploadOss/storeLogoImg';
// 店铺图片上传
export const UploadStoreFile = '/uploadOss/storeImg';

// 获取店铺详细信息
export const GetStoreInfo = '/user/getStoreInfo';

// 获取店铺推荐列表
export const GetStoreRecommandList = '';

// 店铺商品搜索
export const StoreProductSearch = '/user/storeSearchProduct';

// 修改店铺信息
export const SubmitStoreContent = '/user/modifyUserStore';

// 查询商铺列表
export const QueryStoreList = '/user/queryUserStore';

// 获取个人资金账户信息
export const GetWalletInfo = '/account/getBaseInfo';

// 查询账户资金流水记录 type: 1现金2积分 operationType: 1支出2收入
export const GetAccountFlowRecord = '/account/flowRecord';

// 查询账户提现记录
export const GetCashoutRecord = '/account/queryDepositList';

// 设置支付宝账号
export const BindAliPay = '/alipay/modifyBaseInfo';
// 设置银行卡账号
export const BindBankCard = '/bank/modifyBaseInfo';

// 钱包余额提现
export const WalletCashout = '/account/depositBalance';
// 红包提现
export const HongBaoCashout = '/account/depositReward';

// 获取邀请码信息
export const GetInviteList = '/user/queryInviteList';

// 获取已有辅机类目
export const GetAuxCategoryList = '/product/queryAuxCategoryList';


// 获取收货地址
export const GetAddressList = '/user/queryAddressList';
// 获取单一收货地址
export const GetAddressDetail = '/user/getAddressById';
// 添加收货地址
export const AddAddress = '/user/submitAddress';
// 修改收货地址
export const ModifyAddress = '/user/updateAddress';
// 删除收货地址
export const DeleteAddress = '/user/deleteAddress';
// 设置默认收货地址
export const AddressSetDefault = '/user/setAddressDefault';

// 获取提交订单信息
export const GetConfirmOrderInfo = '/order/getProductSettle';
 

// 订单
// 买家-获取订单列表
export const BuyerGetOrderList = '/order/queryBuyerOrderList';
// 获取订单详情
export const GetOrderInfo = '/order/getDetail';
// 买家-取消订单
export const BuyerCancelOrder = '/order/cancelBuyerOrder';
// 买家-待付款立即付款
export const BuyerOrderPay = '/order/toPaymentById';
// 卖家-确认发货
export const OrderConfirmDeliver = '/order/sellerSendDeliver';
// 买家-确认收货
export const OrderConfirmReceipt = '/order/buyerGetDeliver';
// 订单评价
export const OrderEvaluate = '/order/buyerOrderComment';


// 获取商品评论列表
export const GetRateList = '/order/queryProductComment';
// 商品提问
export const ProductAsk = '/product/newQuestion';
// 回答商品问题
export const ProductAnswer = '/product/toAnswerQuestion';
// 获取商品问答列表
export const GetAskAnswerList = '/product/queryQuestionList';
// 获取我的提问列表
export const GetAskList = '/product/queryMyQuestion';
// 获取我的回答列表
export const GetAnswerList = '/product/queryMyAnswer';
// 商家获取商品问答列表
export const SellerGetQuestionList = '/product/querySellerAnswer';


// 获取订单发票信息
export const GetInvoiceInfo = '/order/getInvoiceById';
// 提交开票申请
export const SubmitInvoiceReq = '/order/saveInvoice';

// 卖家获取发票管理列表
export const SellerGetInvoiceManageList = '/order/queryInvoiceSellerList';

// 卖家提交开票运单信息
export const SellerSubmitInvoiceInfo = '/order/confirmSellerInvoice';

// 用户修改电子签章
export const UserUpdateOfficialSeal = '/user/updateOfficialSeal';

// 获取电子合同信息
export const GetContractInfo = '/order/getEContract';
// 买家申请电子合同 
export const BuyerApplyContract = '/order/applyEContract';
// 买家取消申请电子合同
export const BuyerCancelApplyContract = '/order/cancelApplyEContract';
// 买家确认签署电子合同
export const BuyerConfirmContract = '/order/buyerSignEContract';

// 卖家获取合同管理列表
export const GetContractList = '/order/querySellerEContract';

// 卖家生成电子合同
export const SellerCreateContract = '/order/createEContract';

// 获取文章列表
export const GetArticleList = '/article/queryNewsList';

// 获取文章详情
export const GetArticleDetail = '/article/getNewsDetail';


// 申请产品租赁
export const ApplyProductLease = '/order/leaseApply';

// 上传合同文件
export const UploadContract = '/uploadOss/orderEcontract';

// 卖家确认或驳回合同签署
export const SellerFinishContract = '/order/sellerConfirmEContract';


// 买家获取租赁列表
export const BuyerGetLeaseList = '/order/queryLeaseBuyerList';

// 卖家获取租赁列表
export const SellerGetLeaseList = '/order/queryLeaseSellerList';

// 买家取消租赁申请
export const BuyerCancelLease = '/order/cancelLeaseByBuyer';
// 卖家拒绝租赁申请 
export const SellerCancelLease = '/order/cancelLeaseBySeller';
// 卖家同意租赁申请
export const SellerPassLease = '/order/confirmLeaseBySeller';

// 用户创建租赁订单
export const BuyerCreateLeaseOrder = '/order/createLeasePayment';

// 获取租赁订单信息
export const GetLeaseOrderDetail = '/order/getLeaseDetail';

// 支付月租金订单
export const PayLeaseRent = '/order/payLeaseRent';

// 申请加入产业联盟
export const ApplyJoinUnion = '/union/applyJoin';

// 用户获取我的发票模板列表
export const GetInvoiceList = '/user/queryInvoiceList';
// 根据id获取单独发票模板的详情
export const GetInvoiceById ='/user/getInvoiceById';
// 新增发票模板
export const AddInvoice = '/user/submitInvoice';
// 修改发票模板
export const ModifyInvoice = '/user/updateInvoice';
// 删除发票模板
export const DeleteInvoice = '/user/deleteInvoice';
// 设置发票模板位默认
export const InvoiceSetDefault = '/user/setInvoiceDefault';

// 获取区域编码
export const GetAreaCode = '/helipay/getRegionCode';

// 用户开通店铺
export const UserOpenStore = '/user/registerStore';
// 商户申请入网
export const SubmitStoreCert = '/helipay/submitEntry';
// 获取进件信息详情
export const GetStoreCertInfo = '/helipay/getEntryDetail';

// 新版上传通用接口
export const UploadToOss = '/uploadOss/commons';

// 申请融资租赁项目
export const ApplyProject = '/form/submitUserProject';

// 绑定微信公众号
export const BindWeChat = '/wechat/getPmAccessToken';