<template>
  <div class="go-top">
    <div v-show="show" class="content" @click="onBackTop">
      <van-icon name="back-top" size="20" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoTop',
  data() {
    return {
      show: false,
    }
  },

  mounted() {
    window.addEventListener("scroll",this.handleScroll); 
  },

  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const docScroll = document.documentElement.scrollTop;
      
      if (docScroll > 700) {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    onBackTop() {
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style lang="scss" scoped>
.go-top {
  position: fixed;
  bottom: 65px;
  right: 16px;
  z-index: 200;
  .content {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #999;
    color: #666;
    background: #fff;
  }
}
</style>