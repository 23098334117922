import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/storemall',
    name: 'StoreMall',
    component: () => import(/* webpackChunkName: "StoreMall" */ '../views/StoreMall.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/user',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/collect',
    name: 'UserCollect',
    component: () => import(/* webpackChunkName: "userCollect" */ '../views/UserCollect.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/history',
    name: 'UserHistory',
    component: () => import(/* webpackChunkName: "userHistory" */ '../views/UserHistory.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import(/* webpackChunkName: "Protocol" */ '../views/Protocol.vue')
  },
  {
    path: '/union',
    name: 'Union',
    component: () => import(/* webpackChunkName: "Union" */ '../views/Union.vue')
  },
  {
    path: '/unionApply',
    name: 'UnionApply',
    component: () => import(/* webpackChunkName: "UnionApply" */ '../views/UnionApply.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/carbon',
    name: 'Carbon',
    component: () => import(/* webpackChunkName: "Carbon" */ '../views/Carbon.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import(/* webpackChunkName: "Article" */ '../views/Article.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "Cart" */ '../views/Cart.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/applyproj',
    name: 'ApplyProj',
    component: () => import(/* webpackChunkName: "ApplyProj" */ '../views/ApplyProj.vue')
  },
  {
    path: '/applyprojForm',
    name: 'ApplyProjForm',
    component: () => import(/* webpackChunkName: "ApplyProjForm" */ '../views/ApplyprojForm.vue')
  },
  {
    path: '/settlein',
    name: 'Settlein',
    component: () => import(/* webpackChunkName: "Settlein" */ '../views/Settlein.vue')
  },
  {
    path: '/user/intention',
    name: 'Intention',
    component: () => import(/* webpackChunkName: "Intention" */ '../views/user/Intention.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/askAnswer',
    name: 'AskAnswer',
    component: () => import(/* webpackChunkName: "AskAnswer" */ '../views/user/AskAnswer.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/lease',
    name: 'Lease',
    component: () => import(/* webpackChunkName: "Lease" */ '../views/user/Lease.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/buy',
    name: 'Buy',
    component: () => import(/* webpackChunkName: "Buy" */ '../views/user/Buy.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/user/wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "Buy" */ '../views/user/Wallet.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "Setting" */ '../views/Setting.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/edit',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "Edit" */ '../views/setting/Edit.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/address',
    name: 'Address',
    component: () => import(/* webpackChunkName: "Address" */ '../views/setting/Address.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/address/edit',
    name: 'AddressEdit',
    component: () => import(/* webpackChunkName: "AddressEdit" */ '../views/setting/AddressEdit.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/invoice',
    name: 'Invoice',
    component: () => import(/* webpackChunkName: "Invoice" */ '../views/setting/Invoice.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/invoice/edit',
    name: 'InvoiceEdit',
    component: () => import(/* webpackChunkName: "InvoiceEdit" */ '../views/setting/InvoiceEdit.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/setting/signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "Signature" */ '../views/setting/Signature.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/faqs/ask',
    name: 'FaqAsk',
    component: () => import(/* webpackChunkName: "FaqAsk" */ '../views/question/Ask.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/faqs/answer',
    name: 'FaqAnswer',
    component: () => import(/* webpackChunkName: "FaqAnswer" */ '../views/question/Answer.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/faqs/list',
    name: 'FaqList',
    component: () => import(/* webpackChunkName: "FaqList" */ '../views/question/Faqlist.vue'),
  },
  {
    path: '/redirect/gzh',
    name: 'RedirectGzh',
    component: () => import(/* webpackChunkName: "RedirectGzh" */ '../views/redirect/Gzh.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
